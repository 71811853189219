import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createStructuredSelector } from 'reselect';

import datadogClient from '@AppRoot/datadog';
import Header from '@Components/header/header.component';
import { datadogRum } from '@datadog/browser-rum';
import AdministrationPage from '@Pages/administration/administration.component';
import { CountLinenPageID, CountLinenUrl } from '@Pages/count-linen/count-linen-config';
import CountLinenPage from '@Pages/count-linen/count-linen.component';
import HelpPage from '@Pages/help/help.component';
import HomePage from '@Pages/homepage/homepage.component';
import InventoryPage from '@Pages/inventory/inventory-component';
import { InventoryPageID, InventoryUrl } from '@Pages/inventory/inventory-config';
import { ReportsTitles, ReportsUrl } from '@Pages/reports/reports-config';
import ReportsPage from '@Pages/reports/reports.component';
import SetupUsersPage from '@Pages/setup-users/setup-users.component';
import { SetUpPageID, SetUpUrl } from '@Pages/setup/setup-config';
import SetUpPage from '@Pages/setup/setup.component';
import LicenseAgreementPage from '@Pages/sign-in/license-agreement.component';
import ResetPasswordPage from '@Pages/sign-in/reset-password.component';
import SignInPage from '@Pages/sign-in/sign-in.component';
import StyleGuidePage from '@Pages/style-guide/style-guide.component';
import {
  selectBannerNotification,
  selectCurrentCustomer,
  selectCurrentUser,
} from '@State/user/user.selectors';
import isEmptyObject from '@Utils/isEmptyObject';
import BannerNotification from '@Components/banner-notification/banner-notification.component';

const clientSitePrefix = window._appConfig.ENV_PREFIX;

const Routes = (props) => {
  const { currentUser, currentCustomer, bannerNotification } = props;

  let location = useLocation();

  let routes = (
    <Switch>
      <Route path="/login" component={SignInPage} />
      <Route path="/" component={SignInPage} exact />
      <Route path="/license-agreement" component={LicenseAgreementPage} exact />
      <Route path="/reset-password" component={ResetPasswordPage} exact />
      <Redirect to="/" />
    </Switch>
  );

  const [showBannerNotification, setShowBannerNotification] = useState(false);

  useEffect(() => {
    const viewName = location.pathname;
    if (viewName) {
      datadogRum.startView({ name: location.pathname });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isEmptyObject(currentUser) && !isEmptyObject(currentCustomer)) {
      const ddUser = {
        name: currentUser?.username,
        email: currentUser?.emailAddress,
        id: currentUser?.RcID,
        customerId: currentCustomer?.RcID,
        customerName: currentCustomer?.Name,
      };
      datadogClient.setUser(ddUser);
    }
  }, [currentUser, currentCustomer]);

  useEffect(() => {
    const isBannerDismissed = localStorage.getItem('bannerDismissed') === 'true';
    // Show the banner only when a user signs in and there exists a banner notifcation
    if (currentUser && bannerNotification && !isBannerDismissed) {
      // set banner if banner Notifcation includes the client site prefix
      if (bannerNotification.includes(clientSitePrefix)) {
        setShowBannerNotification(true);
      }
    } else {
      setShowBannerNotification(false);
    }
  }, [currentUser, bannerNotification]);

  if (currentUser && currentUser.rules) {
    const userPrivilegeData = currentUser.rules;
    routes = (
      <Switch>
        <Route path="/" component={HomePage} exact />
        {datadogRum.startView()}
        {/* Reporting Routes */}
        {userPrivilegeData &&
          Object.entries(ReportsUrl).map(([pageID, path]) => {
            let userCanView = true;
            if (pageID === 'census') {
              userCanView = userPrivilegeData.canViewSetCensus;
            } else if (pageID === 'budget') {
              userCanView = userPrivilegeData.canViewSetBudget;
            } else if (pageID === 'main') {
              userCanView =
                userPrivilegeData.canViewReports ||
                userPrivilegeData.canViewSetBudget ||
                userPrivilegeData.canViewSetCensus;
            } else {
              userCanView = userPrivilegeData.canViewReports;
            }
            return (
              userCanView && (
                <Route
                  key={pageID}
                  path={path}
                  render={(props) => (
                    <>
                      <Helmet defaultTitle="LinenHelperNet" titleTemplate="%s | LinenHelperNET">
                        <title>{ReportsTitles[pageID]}</title>
                      </Helmet>
                      <ReportsPage {...props} pageID={pageID} />
                    </>
                  )}
                  exact
                />
              )
            );
          })}

        {userPrivilegeData &&
          (userPrivilegeData.canViewCountLinen || userPrivilegeData.canViewCountLinenLinenRoom) && (
            <Route
              path={CountLinenUrl.main}
              render={(props) => <CountLinenPage {...props} activeNav={CountLinenPageID.main} />}
              exact
            />
          )}
        {userPrivilegeData && userPrivilegeData.canViewCountLinen && (
          <Route
            path={CountLinenUrl.cartAndClosets}
            render={(props) => (
              <CountLinenPage {...props} activeNav={CountLinenPageID.cartAndClosets} />
            )}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewCountLinenLinenRoom && (
          <Route
            path={CountLinenUrl.linenRoom}
            render={(props) => <CountLinenPage {...props} activeNav={CountLinenPageID.linenRoom} />}
            exact
          />
        )}

        {userPrivilegeData && userPrivilegeData.canViewCountInventory && (
          <Route
            path={InventoryUrl.main}
            render={(props) => <InventoryPage {...props} activeNav={InventoryPageID.main} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewCountInventory && (
          <Route
            path={InventoryUrl.retirement}
            render={(props) => <InventoryPage {...props} activeNav={InventoryPageID.retirement} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewCountInventory && (
          <Route
            path={InventoryUrl.injection}
            render={(props) => <InventoryPage {...props} activeNav={InventoryPageID.injection} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewCountInventory && (
          <Route
            path={InventoryUrl.physicalInventory}
            render={(props) => (
              <InventoryPage {...props} activeNav={InventoryPageID.physicalInventory} />
            )}
            exact
          />
        )}

        {userPrivilegeData && userPrivilegeData.canViewSetup && (
          <Route
            path={SetUpUrl.main}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.main} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewDepartments && (
          <Route
            path={SetUpUrl.departments}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.departments} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewProducts && (
          <Route
            path={SetUpUrl.products}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.products} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewPacks && (
          <Route
            path={SetUpUrl.packs}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.packs} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewProcedures && (
          <Route
            path={SetUpUrl.procedures}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.procedures} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewOrders && (
          <Route
            path={SetUpUrl.orders}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.orders} />}
            exact
          />
        )}

        {userPrivilegeData && userPrivilegeData.isAdminUser && (
          <Route path={SetUpUrl.advanceUsers} component={SetupUsersPage} exact />
        )}
        {userPrivilegeData && userPrivilegeData.isAdminUser && (
          <Route
            path={SetUpUrl.advanced}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.advanced} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.isAdminUser && (
          <Route
            path={SetUpUrl.advanceCustomers}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.advanceCustomers} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewAccounts && (
          <Route
            path={SetUpUrl.customerAccounts}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.customerAccounts} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewAccounts && (
          <Route
            path={SetUpUrl.accounts}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.accounts} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.isAdminUser && (
          <Route
            path={SetUpUrl.advanceLogTransactions}
            render={(props) => (
              <AdministrationPage {...props} activeNav={SetUpPageID.advanceLogTransactions} />
            )}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.isAdminUser && (
          <Route
            path={SetUpUrl.advanceSystemConfiguration}
            render={(props) => (
              <AdministrationPage {...props} activeNav={SetUpPageID.advanceSystemConfiguration} />
            )}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewAccounts && (
          <Route
            path={SetUpUrl.itemAccounts}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.itemAccounts} />}
            exact
          />
        )}
        {userPrivilegeData && userPrivilegeData.canViewAccounts && (
          <Route
            path={SetUpUrl.departmentAccounts}
            render={(props) => <SetUpPage {...props} activeNav={SetUpPageID.departmentAccounts} />}
            exact
          />
        )}

        <Route path="/help" component={HelpPage} exact />
        <Route
          path={['/style-guide/:element/:option', '/style-guide']}
          component={StyleGuidePage}
        />
        <Redirect to="/" />
      </Switch>
    );
  }

  let style = {};
  if (
    props.location.pathname !== '/license-agreement' &&
    props.location.pathname !== '/reset-password'
  ) {
    style = { marginTop: '136px' };
  } else {
    style = {
      marginTop: '0px',
      padding: '0px !important',
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: '#fff',
    };
  }

  return (
    <Box direction="column">
      <PersistGate loading={null} persistor={props.persistor}>
        {props.location.pathname !== '/license-agreement' &&
          props.location.pathname !== '/reset-password' && (
            <Header currentUser={currentUser} currentCustomer={currentCustomer} />
          )}
        <Box style={style}>{routes}</Box>
        {showBannerNotification && <BannerNotification />}
      </PersistGate>
    </Box>
  );
};

Routes.propTypes = {
  currentUser: PropTypes.object,
};

Routes.defaultProps = {
  currentUser: null,
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentCustomer: selectCurrentCustomer,
  bannerNotification: selectBannerNotification,
});

export default connect(mapStateToProps, null)(withRouter(Routes));
