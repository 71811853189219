import UserActionTypes from './user.types';

export const signInStart = (userNameAndPassword) => ({
  type: UserActionTypes.SIGN_IN_START,
  payload: userNameAndPassword,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const siteInfoStart = () => ({
  type: UserActionTypes.SITE_INFO_START,
});

export const siteInfoSuccess = (info) => ({
  type: UserActionTypes.SITE_INFO_SUCCESS,
  payload: info,
});

export const siteInfoFailure = (error) => ({
  type: UserActionTypes.SITE_INFO_FAILURE,
  payload: error,
});

export const customerStart = (userDetail) => ({
  type: UserActionTypes.CUSTOMERS_START,
  payload: userDetail,
});

export const customerSuccess = (user) => ({
  type: UserActionTypes.CUSTOMERS_SUCCESS,
  payload: user,
});

export const customerFailure = (error) => ({
  type: UserActionTypes.CUSTOMERS_FAILURE,
  payload: error,
});

export const setCustomer = (customer) => ({
  type: UserActionTypes.SET_CURRENT_CUSTOMER,
  payload: customer,
});

export const resetPasswordStart = (name) => ({
  type: UserActionTypes.RESET_PASSWORD_START,
  payload: name,
});

export const resetPasswordSuccess = (data) => ({
  type: UserActionTypes.RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (error) => ({
  type: UserActionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});

export const loginMessageStart = (userDetail) => ({
  type: UserActionTypes.LOGIN_MESSAGE_START,
  payload: userDetail,
});

export const loginMessageSuccess = (user) => ({
  type: UserActionTypes.LOGIN_MESSAGE_SUCCESS,
  payload: user,
});

export const loginMessageFailure = (error) => ({
  type: UserActionTypes.LOGIN_MESSAGE_FAILURE,
  payload: error,
});

export const bannerNotificationStart = (userDetail) => ({
  type: UserActionTypes.BANNER_NOTIFICATION_START,
  payload: userDetail,
});

export const bannerNotificationSuccess = (user) => ({
  type: UserActionTypes.BANNER_NOTIFICATION_SUCCESS,
  payload: user,
});

export const bannerNotificationFailure = (error) => ({
  type: UserActionTypes.BANNER_NOTIFICATION_FAILURE,
  payload: error,
});

export const clearMessageStart = () => ({
  type: UserActionTypes.CLEAR_MESSAGE_START,
  payload: null,
});

export const clearMessageSuccess = (user) => ({
  type: UserActionTypes.CLEAR_MESSAGE_SUCCESS,
  payload: user,
});

export const clearMessageFailure = (error) => ({
  type: UserActionTypes.CLEAR_MESSAGE_FAILURE,
  payload: error,
});
