import UserActionTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  currentCustomer: null,
  siteInfo: null,
  customers: null,
  bannerNotification: null,
  resetPassword: null,
  loginMessages: null,
  error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        currentCustomer: null,
        customers: null,
        error: null,
      };
    case UserActionTypes.SITE_INFO_SUCCESS:
      return {
        ...state,
        siteInfo: action.payload,
        error: null,
      };
    case UserActionTypes.CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        currentCustomer: action.payload && action.payload.length === 1 ? action.payload[0] : null,
        error: null,
      };
    case UserActionTypes.SET_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.payload,
        error: null,
      };
    case UserActionTypes.BANNER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        bannerNotification: action.payload,
        error: null,
      };
    case UserActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.payload,
        error: null,
      };
    case UserActionTypes.LOGIN_MESSAGE_SUCCESS:
      return {
        ...state,
        loginMessages: action.payload,
        error: null,
      };
    case UserActionTypes.CLEAR_MESSAGE_SUCCESS:
      return {
        ...state,
        loginMessages: [],
        error: null,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SITE_INFO_FAILURE:
    case UserActionTypes.CUSTOMERS_FAILURE:
    case UserActionTypes.BANNER_NOTIFICATION_FAILURE:
    case UserActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
