import 'rc-menu/assets/index.css';

import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import logo from '@Assets/logo.png';
import LoginMessage from '@Components/login-message/login-message.component';
import SelectCustomer from '@Components/select-customer/select-customer.component';
import { setCustomer, signOutStart } from '@State/user/user.actions';
import {
  selectBannerUrl,
  selectCurrentCustomer,
  selectCurrentUser,
  selectCustomers,
  selectLoginMessages,
  selectPrivileges,
} from '@State/user/user.selectors';

import {
  HeaderBox,
  HeaderBreadcrumb,
  HeaderBreadcrumbsBox,
  HeaderLabel,
  HeaderMenu,
  HeaderMenuItem,
  HeaderMenuLink,
  HeaderSubMenu,
  LogoContainer,
} from './header.styles';

window.addEventListener('resize', () => {
  const columnResizers = document.querySelectorAll('.resizer-handle-column');
  for (let i = 0; i < columnResizers.length; i++) {
    const allColumns = columnResizers[i].parentElement.children;
    allColumns[0].style.width = ''; // left column
    allColumns[2].style.width = ''; // right column
  }
});

const buildBreadcrumbs = (currentPath, siteStructure, homepageName) => {
  const crumbDetails = [];

  if (currentPath && siteStructure) {
    if (homepageName) {
      crumbDetails.push({
        to: '/',
        label: homepageName,
      });
    }

    const findPathNodes = (structure, path, pathPrefix, parentFlattens) => {
      for (let i = 0; i < structure.length; i++) {
        const node = structure[i];
        if (node.link === '/') {
          continue;
        }

        // Structure node links may have multiple path segments, so we can't
        // just split path on slashes to find the matching structure.
        if (node.link === path.substring(0, node.link.length)) {
          const innerPath = path.substring(node.link.length);
          const goesDeeper = innerPath && innerPath !== '/' && node.submenus;
          const isLink = goesDeeper && node.navmap && !parentFlattens;

          const detail = {
            label: node.title,
          };

          if (isLink) {
            detail.to = pathPrefix + node.link;
          }

          crumbDetails.push(detail);

          if (goesDeeper) {
            const innerPathPrefix = pathPrefix + path.substring(0, node.link.length);
            findPathNodes(node.submenus, innerPath, innerPathPrefix, Boolean(node.multinavmap));
          }

          return true;
        }
      }

      return false;
    };

    findPathNodes(siteStructure, currentPath, '');
  }

  if (crumbDetails.length > 0) {
    if (crumbDetails.length === 1) {
      delete crumbDetails[0].to;
    }

    const titleCrumbString = crumbDetails
      .slice(1) // get all but homepage element
      .reverse() // reverse the order
      .map((crumb) => crumb.label) // extract just the label
      .join(' | '); // join on pipe

    return (
      <>
        <Helmet defaultTitle="LinenHelperNET" titleTemplate="%s | LinenHelperNET">
          <title>{titleCrumbString}</title>
        </Helmet>
        <HeaderBreadcrumbsBox
          style={{
            position: 'fixed',
            backgroundColor: 'rgb(207,211,227)',
            zIndex: '10',
          }}
        >
          {crumbDetails.map((crumb, index) => (
            <HeaderBreadcrumb key={`crumb_${index}`} {...crumb} notFirst={index > 0} />
          ))}
        </HeaderBreadcrumbsBox>
      </>
    );
  }
  return null;
};

const bannerImage = window._appConfig.BANNER_IMAGE;

const Header = (props) => {
  const {
    currentUser,
    bannerUrl,
    userPrivileges,
    currentCustomer,
    customers,
    signOutStart,
    setCustomer,
    loginMessages,
  } = props;
  const [showCustomer, setShowCustomer] = useState(!currentCustomer);
  const [showSingleCustomer, setShowSingleCustomer] = useState(1);
  const [hasBannerImage, setHasBannerImage] = useState(true);
  const [currOpenKeys, setCurrOpenKeys] = useState([]);
  const [showLoginMessages, setShowLoginMessages] = useState();
  const [showedLoginMessages, setShowedLoginMessages] = useState();

  /// / FIXME: this blocks `setShowCustomer(true)` while `currentCustomer` is set.
  /// / Find another way to _not_ show the customer select when a customer is
  /// / automatically selected (e.g. when there's only 1 customer available)
  useEffect(() => {
    if (currentUser && customers) {
      if (customers.length === 1) {
        setShowCustomer(false);
      } else if (customers.length > 0) {
        if (loginMessages) {
          if (loginMessages.length > 0) setShowCustomer(false);
          else if (showedLoginMessages) {
            setTimeout(() => {
              setShowCustomer(true);
            }, 500);
            setShowedLoginMessages(false);
          } else setShowCustomer(true);
        } else setShowCustomer(false);
      }
    }
  }, [loginMessages, customers]);

  useEffect(() => {
    if (loginMessages) {
      if (loginMessages.length > 0) {
        setShowLoginMessages(true);
        setShowedLoginMessages(true);
      } else setShowLoginMessages(false);
    } else {
      setShowLoginMessages(false);
    }
  }, [loginMessages]);

  const lowerCaseFirstLetter = (string) => {
    let data = '';
    data = string.charAt(0).toLowerCase() + string.slice(1);
    return data;
  };

  const buildMenuItem = (index, urlPrefix, item) => {
    return (
      <HeaderMenuItem key={index} onMouseEnter={onMouseEnterMenu}>
        <HeaderMenuLink to={urlPrefix + item.link}>{item.title}</HeaderMenuLink>
      </HeaderMenuItem>
    );
  };

  const buildSubMenu = (index, urlPrefix, item, depth = 0) => {
    return (
      <HeaderSubMenu key={index} title={item.title} onMouseEnter={onMouseEnterMenu}>
        {item.submenus
          .filter(
            (menuItem) =>
              menuItem.dropdown && // check conditions where the required abilities of the menu items exist in the user's privileges
              (userPrivileges[`canView${menuItem.requiredAbilities[1].split('_').pop()}`] ||
                userPrivileges[
                  `canView${menuItem.requiredAbilities[1].split('_').pop().slice(6)}`
                ] ||
                (menuItem.requiredAbilities[1].split('_').pop() === 'CountLinenRoom' &&
                  userPrivileges.canViewCountLinenLinenRoom) ||
                userPrivileges[
                  `${lowerCaseFirstLetter(menuItem.requiredAbilities[1].split('_').pop())}`
                ]),
          )
          .map((innerItem, innerIndex) => {
            return innerItem.submenus && innerItem.submenus.length > 0
              ? buildSubMenu(`${index}_${innerIndex}`, urlPrefix + item.link, innerItem, depth + 1)
              : buildMenuItem(`${index}_${innerIndex}`, urlPrefix + item.link, innerItem);
          })}
      </HeaderSubMenu>
    );
  };

  const onMouseEnterMenu = (event) => {
    const ctr = event.key.match(/_/g) || [];

    let keys = [...currOpenKeys];
    if (!keys.includes(event.key)) {
      if (ctr.length === 0) {
        keys = [];
        setCurrOpenKeys([event.key]);
      } else {
        keys.splice(ctr.length, keys.length);
        setCurrOpenKeys(keys.concat([event.key]));
      }
    }
  };
  const onClearMenu = () => {
    setCurrOpenKeys([]);
  };

  const styleGuideTriggerThreshold = 3;
  const [styleGuideTriggerCount, setStyleGuideTriggerCount] = useState(0);
  const delayedStyleGuideTrigger = () => {
    if (styleGuideTriggerCount === 0) {
      setTimeout(() => setStyleGuideTriggerCount(0), 5000);
    }
    if (styleGuideTriggerCount < styleGuideTriggerThreshold) {
      setStyleGuideTriggerCount(styleGuideTriggerCount + 1);
    }
  };

  return (
    <>
      {buildBreadcrumbs(
        window.location.pathname,
        currentUser && currentUser.websiteStructure,
        currentUser && currentUser.HomePageName,
      )}
      <HeaderBox
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '10',
        }}
        blankBg={hasBannerImage}
      >
        <LogoContainer
          to="/"
          bannerUrl={bannerImage}
          defaultLogoSrc={logo}
          setHasBannerImage={setHasBannerImage}
          hasBannerImage={hasBannerImage}
          onClick={delayedStyleGuideTrigger}
        />

        {userPrivileges && userPrivileges.canViewSelectCustomer && (
          <SelectCustomer
            currentCustomer={currentCustomer}
            show={showCustomer}
            setShow={setShowCustomer}
            setShowSingleCustomer={setShowSingleCustomer}
            showSingleCustomer={showSingleCustomer}
          />
        )}

        <LoginMessage
          currentCustomer={currentCustomer}
          show={showLoginMessages}
          setShow={setShowLoginMessages}
          setShowSingleCustomer={setShowSingleCustomer}
          showSingleCustomer={showSingleCustomer}
        />

        <HeaderMenu
          subMenuCloseDelay={0.2}
          openKeys={currOpenKeys}
          onSelect={onClearMenu}
          onOpenChange={onClearMenu}
        >
          {currentUser &&
            currentUser.websiteStructure &&
            currentUser.websiteStructure
              .filter((item) => item.dropdown)
              .map((item, index) => {
                return item.submenus && item.submenus.length > 0
                  ? buildSubMenu(index, '', item)
                  : buildMenuItem(index, '', item);
              })}
          {userPrivileges &&
          userPrivileges.canViewSelectCustomer &&
          customers &&
          customers.length > 0 ? (
            <HeaderMenuItem>
              <HeaderMenuLink to="#" onClick={() => setShowCustomer(true)}>
                Select Customer
              </HeaderMenuLink>
            </HeaderMenuItem>
          ) : null}
          {currentUser ? (
            <HeaderMenuItem>
              <HeaderMenuLink
                to="#"
                onClick={() => {
                  localStorage.setItem('bannerDismissed', 'false');
                  signOutStart();
                }}
              >
                Sign Out
              </HeaderMenuLink>
            </HeaderMenuItem>
          ) : (
            <HeaderMenuItem>
              <HeaderMenuLink to="#">Sign In</HeaderMenuLink>
            </HeaderMenuItem>
          )}
          {currentUser && currentCustomer ? (
            <HeaderLabel disabled>
              <HeaderMenuLink to="#">
                Selected Customer:
                {currentCustomer.Name}
              </HeaderMenuLink>
            </HeaderLabel>
          ) : null}
        </HeaderMenu>
        {styleGuideTriggerCount === styleGuideTriggerThreshold ? (
          <Redirect to="/style-guide" />
        ) : null}
      </HeaderBox>
    </>
  );
};

Header.propTypes = {
  currentUser: PropTypes.object,
  customers: PropTypes.array,
  loginMessages: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  customers: selectCustomers,
  userPrivileges: selectPrivileges,
  currentCustomer: selectCurrentCustomer,
  bannerUrl: selectBannerUrl,
  loginMessages: selectLoginMessages,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
  setCustomer: (customer) => dispatch(setCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
