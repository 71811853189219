import LogRocket from 'logrocket';
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '@API';
import { environment } from '@AppRoot/environments/environment';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { userPermissions } from '@Helpers/permissions.helper';

import {
  bannerNotificationStart,
  bannerNotificationFailure,
  bannerNotificationSuccess,
  clearMessageFailure,
  clearMessageSuccess,
  customerFailure,
  customerStart,
  customerSuccess,
  loginMessageFailure,
  loginMessageStart,
  loginMessageSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  signInFailure,
  signInSuccess,
  signOutFailure,
  signOutSuccess,
  siteInfoFailure,
  siteInfoSuccess,
} from './user.actions';
import UserActionTypes from './user.types';

const loginApi = (params) => {
  return api.post('/security/validate-user', {
    Name: params.username,
    Password: params.password,
  });
};

export function* signIn(action) {
  try {
    const params = { ...action.payload };
    const { data } = yield call(loginApi, params);

    const userDetails = data.data;
    if (userDetails.rules) {
      // Can only save objects, not functions in state, so save this object
      userDetails.rules = userPermissions(new Ability(unpackRules(userDetails.rules)));
      if (userDetails.rules.canViewCountLinenLinenRoom) userDetails.rules.canViewCountLinen = true;
    }

    LogRocket.identify(userDetails.guid, {
      userRcID: userDetails.RcID,
      username: userDetails.username,
      userDescription: userDetails.UserDesc,
      email: userDetails.emailAddress,
      homepageRole: userDetails.HomePageName,
    });

    yield put(signInSuccess(userDetails));
    yield put(customerStart({}));
    yield put(loginMessageStart({}));
    yield put(bannerNotificationStart({}));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* onSignInStart() {
  yield takeLatest(UserActionTypes.SIGN_IN_START, signIn);
}

export function* signOut() {
  try {
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

const siteInfoApi = () => {
  return api.post('/siteconfig/get-web-site-info', {});
};

export function* siteInfo(action) {
  try {
    const { data } = yield call(siteInfoApi, action.payload);
    yield put(siteInfoSuccess(data.data));
  } catch (error) {
    yield put(siteInfoFailure(error));
  }
}

export function* onSiteInfoInStart() {
  yield takeLatest(UserActionTypes.SITE_INFO_START, siteInfo);
}

const customersApi = (reqParams) => {
  return api.post('/setuptables/get-customers', reqParams);
};

export function* customers(action) {
  try {
    const params = { ...action.payload };
    const { data } = yield call(customersApi, params);
    yield put(customerSuccess(data.data));
  } catch (error) {
    yield put(customerFailure(error));
  }
}

export function* onCustomersStart() {
  yield takeLatest(UserActionTypes.CUSTOMERS_START, customers);
}

const bannerNotificationApi = async (reqParams) => {
  try {
    const response = await axios.get(window._appConfig.BANNER_NOTIFICATION_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function* bannerNotification(action) {
  try {
    const params = { ...action.payload };
    const { clientsWithBanner } = yield call(bannerNotificationApi, params);
    yield put(bannerNotificationSuccess(clientsWithBanner));
  } catch (error) {
    yield put(bannerNotificationFailure(error));
  }
}

export function* onBannerNotificationStart() {
  yield takeLatest(UserActionTypes.BANNER_NOTIFICATION_START, bannerNotification);
}

const loginMessagesApi = (reqParams) => {
  return api.post('/security/get-login-messages', reqParams);
  // return api.post('/setuptables/get-customers', reqParams);
};

export function* loginMessages(action) {
  try {
    const params = { dynamoDB: environment.dynamoDB };
    const { data } = yield call(loginMessagesApi, params);
    yield put(loginMessageSuccess(data.data));
  } catch (error) {
    yield put(loginMessageFailure(error));
  }
}

export function* onLoginMessagesStart() {
  yield takeLatest(UserActionTypes.LOGIN_MESSAGE_START, loginMessages);
}

export function* clearMessages(action) {
  try {
    yield put(clearMessageSuccess());
  } catch (error) {
    yield put(clearMessageFailure(error));
  }
}

export function* onClearMessagesStart() {
  yield takeLatest(UserActionTypes.CLEAR_MESSAGE_START, clearMessages);
}

const resetPasswordApi = (reqParams) => {
  return api.post('/users/reset-password', { Name: reqParams.name });
};

export function* resetPassword(action) {
  try {
    const params = { ...action.payload };
    const { data } = yield call(resetPasswordApi, params);
    yield put(resetPasswordSuccess(data.data));
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(UserActionTypes.RESET_PASSWORD_START, resetPassword);
}

export function* userSagas() {
  yield all([
    call(onSignInStart),
    call(onSignOutStart),
    call(onSiteInfoInStart),
    call(onCustomersStart),
    call(onBannerNotificationStart),
    call(onLoginMessagesStart),
    call(onClearMessagesStart),
    call(onResetPasswordStart),
  ]);
}
