import React, { useState } from 'react';
import './banner-notification.styles.css';

const BannerNotification = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="banner-notification">
      <div className="banner-content">
        <span className="banner-text">
          🚀
          <strong>
            {' '}
            Exclusive Invite: Beta Test the New LinenHelper! Be the first to explore our redesigned
            portal and enter to win up to $500!
          </strong>
        </span>
        <ul className="banner-list">
          <li>
            <strong>What's in it for you:</strong>
          </li>
          <li>
            ✅ <strong>Early Access</strong> - Be among the first to experience our newly redesigned
            web portal
          </li>
          <li>
            ✅ <strong>Make an Impact</strong> - Share valuable feedback to help shape future
            enhancements
          </li>
          <li>
            ✅ <strong>Expert Support</strong> - Participate in guided testing with help from
            LinenHelper specialists
          </li>
        </ul>
        <span className="banner-instructions">
          ✉️ <strong>Ready to join?</strong> Reply to{' '}
          <a href="mailto:support@linenmaster.com" className="banner-email">
            support@linenmaster.com
          </a>{' '}
          now to secure your spot!
        </span>
      </div>
      <button
        className="banner-close-button"
        onClick={() => {
          localStorage.setItem('bannerDismissed', 'true');
          setIsVisible(false);
        }}
        aria-label="Close notification"
      >
        Dismiss
      </button>
    </div>
  );
};

export default BannerNotification;
